import { Grid } from './puzzle';
import LetterBox from './LetterBox';

interface Props {
    letters: Grid,
    onBoxClick: (row: number, col: number) => void,
    enableClick: boolean
};

const styles = {
    root: {
        margin: 'auto',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center'
    },
    row: {
        display: 'flex',
        flexFlow: 'column',
        flexBasis: 100
    },
    letter: {
        display: 'flex',
        alignItems: 'stretch',
        flexBasis: 100
    }
};

const PuzzleDisplay = ({
    letters,
    onBoxClick,
    enableClick
}: Props) => {

    return (
        <div style={styles.root}>
            {
                letters.letters.map((row, rowIndex) => {
                    return (
                        <div key={rowIndex} style={styles.row}>
                            {row.map((col, colIndex) => (
                                <div key={colIndex} style={styles.letter}>
                                    <LetterBox enableClick={enableClick} box={col} onClick={() => onBoxClick(rowIndex, colIndex)}/>
                                </div>
                            ))}
                        </div>
                    );
                })
            }
        </div>
    );
};

export default PuzzleDisplay;