interface Props {
    enabled: boolean,
    onSuccess: () => void,
    onFailure: () => void
}

const AdminInterface = ({
    enabled,
    onSuccess,
    onFailure
}: Props) => {
    return (
        <div>
            <button type="button" disabled={!enabled} onClick={onFailure}>Not a word!</button>
            <button type="button" disabled={!enabled} onClick={onSuccess}>Is a word!</button>
        </div>
    );
};

export default AdminInterface;