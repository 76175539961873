import { useState, useCallback, useEffect, useMemo } from 'react';

import { db, collection } from './firebase';
import { Grid, toggleButton, turnOffGrid, updateDefault, hasCompletedWord } from './puzzle';
import PuzzleDisplay from './PuzzleDisplay';
import AdminInterface from './AdminInterface';
import SuperUserInterface from './SuperUserInterface';

const defaultGrid: Grid = {
  letters: [],
  selectedIndexes: []
};

type UserType = 'user' | 'admin';

interface Props {
  documentId: string
}

const App = ({ documentId }: Props) => {
  const [loading, setLoading] = useState(!!documentId);
  const [grid, setGrid] = useState(defaultGrid);
  const [errorMessage, setErrorMessage] = useState('');
  const [userType, setUserType] = useState<UserType>(localStorage.getItem('user-type') as UserType || 'user');
  const docRef = useMemo(() => collection.doc(documentId || 'nononono'), [documentId]);

  const reallySetGrid = useCallback((newGrid: Grid) => {
    docRef.set(newGrid);
  }, [docRef]);

  const produceNewGrid = useCallback((gridFn: (g: Grid) => Grid) => {
    db.runTransaction(tx => {
      return tx.get(docRef).then(doc => {
        const newGrid = gridFn(doc.data()!);
        tx.set(docRef, newGrid);
      });
    });
  }, [docRef]);

  useEffect(() => {
    const unsubscribe = docRef.onSnapshot(doc => {
      const firestoreGrid = doc.data();
      if (firestoreGrid) {
        setGrid(firestoreGrid);
      } else {
        setErrorMessage('Invalid document ID. Please reach out to your DM about the link you received.');
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, [docRef]);

  const boxClick = useCallback((row, col) => {
    const newGrid = toggleButton(grid, row, col);
    setGrid(newGrid);
    produceNewGrid(g => toggleButton(g, row, col));
  }, [grid, produceNewGrid]);

  return loading ? <div>Loading...</div> : errorMessage ? <div>{errorMessage}</div> : (
    <div style={{ paddingTop: '100px' }}>
      <SuperUserInterface onAdmin={() => setUserType('admin')} onUser={() => setUserType('user')}/>
      <PuzzleDisplay letters={grid} onBoxClick={boxClick} enableClick={!hasCompletedWord(grid)} />
      { userType === 'admin' && <AdminInterface enabled={hasCompletedWord(grid)} onFailure={() => reallySetGrid(turnOffGrid(grid))} onSuccess={() => reallySetGrid(updateDefault(grid))} /> }
    </div>
  );
};

export default App;
