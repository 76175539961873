import { useMemo, CSSProperties } from 'react';
import { Box } from './puzzle';
import stone0 from './images/stone-tiles/0-bigger.png';
import stone1 from './images/stone-tiles/1-bigger.png';
import stone2 from './images/stone-tiles/2-bigger.png';
import stone3 from './images/stone-tiles/3-bigger.png';

const stoneTiles = [
    stone0,
    stone1,
    stone2,
    stone3
];

interface Props {
    box: Box
    onClick: () => void,
    enableClick: boolean
};

const onBackground = 'rgba(237, 208, 76, 0.8)'; //'#edd04c';
const defaultedBackground = 'rgba(181, 169, 116, 0.75)'; //'#b5a974';
const offBackground = 'rgba(191, 190, 186, 0.5)'; //'#bfbeba';



const LetterBox = ({
    box: {
        letter,
        on,
        defaulted
    },
    enableClick,
    onClick
}: Props) => {
    const stoneTileIndex = useMemo(() => Math.floor(Math.random() * 4), []);
    const containerStyle = useMemo(() => ({
        backgroundImage: `url(${stoneTiles[stoneTileIndex]})`,
        backgroundSize: 'contain',
        width: '100%',
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch'
    }), [stoneTileIndex]);

    const overlayStyle = useMemo(() => {
        const base: CSSProperties = {
            color: '5e5e5e',
            background: on ? onBackground : defaulted ? defaultedBackground : offBackground,
            width: '100%',
            height: '100%',
            flexGrow: 1,
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '5rem',
            border: '1px solid #000'
        };

        if (enableClick) {
            base.cursor = 'pointer'
        }

        return base;
    }, [on, defaulted, enableClick]);
    return (
        <div style={containerStyle}>
            <div onClick={enableClick ? onClick : undefined} style={overlayStyle}>
                {letter}
            </div>
        </div>
    );
};

export default LetterBox;