import { collection } from './firebase';
import { createGrid } from './puzzle';

const startingGrid = createGrid([
    'CFLPS',
    'EILRU',
    'AEGNT',
    'CEFHR',
    'EHNPT'
  ], [
    4, 2, 1, 1, 3
]);

const createNewFirebaseDoc = (): Promise<string> => {
    // Save starting grid as new doc
    // Return new doc ID
    return collection.add(startingGrid).then(docRef => docRef.id);
};

export {
    createNewFirebaseDoc
};