import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';

import firebaseConfig from './firebaseConfig';
import { converter } from './puzzle';

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const collection = db.collection('dnd-wizard-tower').withConverter(converter);

export {
    db,
    collection
};