const lettersToArray = (s: string): string[] => Array.from(s.matchAll(/[a-zA-Z]/g), match => match[0]);

const flatMap = <T>(arr: T[][]): T[] => arr.reduce((agg, cur) => {
    agg.push(...cur);
    return agg;
}, []);

const unflatMap = <T>(arr: T[], size: number): T[][] => {
    const result: T[][] = [];
    arr.forEach((val, idx) => {
        if (idx % size === 0) {
            result.push([]);
        }

        result[result.length - 1].push(val);
    });

    return result;
};

export {
    lettersToArray,
    flatMap,
    unflatMap
};