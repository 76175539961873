import { ReactNode } from 'react';

interface Props {
    children: ReactNode
}

const Development = ({
    children
}: Props) => {
    if (process.env.NODE_ENV === 'development') {
        return <>{children}</>;
    }

    return <></>;
};

export default Development;