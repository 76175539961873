import firebase from 'firebase';
import { lettersToArray, flatMap, unflatMap } from './helpers';

type Box = {
    letter: string,
    on: boolean,
    defaulted: boolean
};

type Grid = {
    letters: Box[][],
    selectedIndexes: number[]
};

interface FirestoreGrid {
    letters: Box[],
    selectedIndexes: number[],
    size: number
}

const createGrid = (letters: string[], defaultedIndexes: number[]): Grid => {
    return {
        selectedIndexes: [],
        letters: letters.map((line, lineIndex) => lettersToArray(line).map((letter, letterIndex) => ({ letter, on: false, defaulted: defaultedIndexes[lineIndex] === letterIndex })))
    };
};

const cloneGrid = (grid: Grid): Grid => {
    return {
        selectedIndexes: [...grid.selectedIndexes],
        letters: grid.letters.map(row => row.map(box => ({ ...box })))
    };
};

const turnOffGrid = (grid: Grid): Grid => {
    const newGrid = cloneGrid(grid);
    newGrid.selectedIndexes = [];
    newGrid.letters.forEach(row => row.forEach(box => box.on = false));

    return newGrid;
};

const updateDefault = (grid: Grid): Grid => {
    const newGrid = cloneGrid(grid);
    newGrid.letters.forEach((row, rowIndex) => {
        row.forEach((col, colIndex) => {
            col.on = false;
            col.defaulted = colIndex === newGrid.selectedIndexes[rowIndex];
        });
    });

    newGrid.selectedIndexes = [];
    return newGrid;
};

const toggleButton = (grid: Grid, rowIndex: number, columnIndex: number): Grid => {
    const expectedNextIndex = grid.selectedIndexes.length % grid.letters.length;
    if (rowIndex === expectedNextIndex) {
        const newGrid = cloneGrid(grid);

        newGrid.selectedIndexes.push(columnIndex);
        newGrid.letters[rowIndex][columnIndex].on = true;

        return newGrid;
    } else {
        return turnOffGrid(grid);
    }
};

const hasCompletedWord = (grid: Grid): boolean => {
    return grid.selectedIndexes.length === grid.letters.length;
};

const converter: firebase.firestore.FirestoreDataConverter<Grid | null> = {
    toFirestore(grid: Grid): FirestoreGrid {
        return {
            selectedIndexes: grid.selectedIndexes,
            letters: flatMap(grid.letters),
            size: grid.letters.length
        }
    },
    fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot): Grid | null {
        if (!snapshot.exists) {
            return null;
        }

        const data = snapshot.data() as FirestoreGrid;
        return {
            selectedIndexes: data.selectedIndexes,
            letters: unflatMap(data.letters, data.size)
        };
    }
};

export type {
    Box, Grid
};

export {
    createGrid,
    toggleButton,
    updateDefault,
    hasCompletedWord,
    cloneGrid,
    turnOffGrid,
    converter
};