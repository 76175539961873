const firebaseConfig = {
    apiKey: "AIzaSyBHX9UhJuQGTYSSI2Fxiq4Q262woVau9bE",
    authDomain: "razz-apps.firebaseapp.com",
    projectId: "razz-apps",
    storageBucket: "razz-apps.appspot.com",
    messagingSenderId: "455090021042",
    appId: "1:455090021042:web:85cac47f1b5e9f50ac9fab",
    measurementId: "G-4PLW4YQYPZ"
};

export default firebaseConfig;