import { useState } from 'react';
import { createNewFirebaseDoc } from './puzzleGenerator';
import Development from './Development';

interface Props {
    onUser: () => void,
    onAdmin: () => void
};

const goToDocId = (docId: string) => window.location.pathname = `/${docId}`;

const SuperUserInterface = ({
    onUser,
    onAdmin
}: Props) => {
    const [docId, setDocId] = useState(window.location.pathname.substring(1));
    return (
        <Development>
            <div>
                <div>
                    <label htmlFor="doc-id">Document ID:</label>
                    <input id="doc-id" name="doc-id" type="text" value={docId} onChange={evt => setDocId(evt.target.value)} />
                    <button type="button" onClick={() => goToDocId(docId)}>Load Document</button>
                </div>
                <div>
                    <button type="button" onClick={() => createNewFirebaseDoc().then(goToDocId)}>Generate</button>
                </div>
                <div>
                    <button type="button" onClick={onUser}>User</button>
                    <button type="button" onClick={onAdmin}>Admin</button>
                </div>
            </div>
        </Development>
    );
};

export default SuperUserInterface;